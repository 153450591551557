import React from 'react'
import SliderCable from './SliderCable'
import { Topbar } from './Topbar'

const PromoCombo = () => {
  return (
    <div>
        <Topbar/>
        <SliderCable/>
    </div>
  )
}

export default PromoCombo