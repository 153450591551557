import React from 'react'
import promo from '../assets/images/Marca-blanca-tv.png'
import promoMobile from '../assets/images/Marca-blanca-tv-mobile.png'

const SliderCombo = () => {
  return (
    <div className='carousel'>
      {window.innerWidth <= 1001? (
        <img
          src={promoMobile}
          alt="promocionMendozaMobile"
          style={{ width: "100%", height: "100%" }}
        />
      ):(
        <img
          src={promo}
          alt="promocionMendoza"
          style={{ width: "100%", height: "40vw" }}
        />
      )}
    </div>
  )
}

export default SliderCombo