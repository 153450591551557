import React from 'react'
import SliderInternet from './SliderInternet'
import { Topbar } from './Topbar'

const PromoCombo = () => {
  return (
    <div>
        <Topbar/>
        <SliderInternet/>
    </div>
  )
}

export default PromoCombo