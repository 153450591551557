import React from 'react'
import SliderCombo from './SliderCombo'
import { Topbar } from './Topbar'

const PromoCombo = () => {
  return (
    <div>
        <Topbar/>
        <SliderCombo/>
    </div>
  )
}

export default PromoCombo