import "./assets/css/App.css";
import PromoCombo from './components/PromoCombo';
import PromoInternet from './components/PromoInternet';
import PromoCable from './components/PromoCable';
import { HashRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route exact path='/' element={<PromoCombo/>}></Route>
          <Route exact path='/promo-combo' element={<PromoCombo/>}></Route>
          <Route exact path='/promo-internet' element={<PromoInternet/>}></Route>
          <Route exact path='/promo-tv' element={<PromoCable/>}></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
