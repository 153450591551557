import React, { useState, useEffect } from "react";
import "../assets/css/topbar.css";
import phoneIcon from "../assets/images/phone-number.svg";


export const Topbar = () => {
  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
      <>
        {data !== null ? (
            
              <div className="topbar-container">
                <div className="floating">
                  <div className="floating-button" type="button">
                    <img src={phoneIcon} alt="Phone" className="phoneIcon" />
                    <a href={`tel:${data.phoneNumber}`}>
                      <b>{data.phoneNumberIcon}</b>
                    </a>
                  </div>
                </div>
              </div>
      ):(<></>)}
    </>
  );
};
